//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex'
import { APP_RATING, GOOGLE_PLAY_APP_LINK, APPLE_STORE_APP_LINK } from '~/constants'

export default {
    name: 'PageApp',
    asyncData ({ app, redirect }) {
        if (app.$device.isIos) {
            redirect(APPLE_STORE_APP_LINK)
        } else if (app.$device.isAndroid) {
            redirect(GOOGLE_PLAY_APP_LINK)
        }
    },
    data: () => ({
        appRating: APP_RATING,
        googlePlayAppLink: GOOGLE_PLAY_APP_LINK,
        appleStoreAppLink: APPLE_STORE_APP_LINK
    }),
    head () {
        const title = 'Get Udimi app for Android or iOS'
        const description = 'Download Udimi app for your Android smartphone or iPhone.'
        const keywords = 'udimi app, udimi android, udimi ios, udimi android app, udimi ios app, udimi mobile app.'

        return {
            title,
            meta: [
                { name: 'og:title', property: 'og:title', hid: 'og:title', content: title },
                { name: 'twitter:title', hid: 'twitter:title', content: title },
                { name: 'description', hid: 'description', content: description },
                { name: 'og:description', property: 'og:description', hid: 'og:description', content: description },
                { name: 'twitter:description', hid: 'twitter:description', content: description },
                { name: 'keywords', hid: 'keywords', content: keywords }
            ]
        }
    },
    computed: {
        ...mapGetters({
            isMobile: 'isMobile'
        })
    }
}
